import React from 'react'
import mobiscroll from '@mobiscroll/react'
import PageWrapper from '../../helper/PageWrapper'
import i18 from 'i18next'
import field from './field.svg'
import StaigeButton from '../../components/styles/Button'
import i18n from '../../languages/i18n'

import contractContainer, { ContractContainer } from '../../services/contractContainer'
import { RouteComponentProps } from 'react-router'
import { isAxiosError } from '../../helper/UtilityFunctions'
import { BrowserInfoContainer } from '../../services/browserInfoContainer'
import { CameraData, Role } from '@soccerwatch/common'
import { searchAndSortGetCallAs } from '../../services/serviceHelper'
import { getCameraCornerPointsAll, getCameraCornerPointsFieldName } from '../../api/api-camera'
import { PanoramaPlayer } from '../../components/panoramaPlayer/player'

import './FieldMask.scss'
import { LivekitContainer } from '../../services/livekitContainer'

const defaultCorners = [1713, 1274, 7, 1289, 3823, 9, 2127, 2539]

type FieldMaskPageProps = RouteComponentProps & {
  cameraId: string
  camera: CameraData
  contractContainer: ContractContainer
  fieldMask?: {
    lastCalibration: unknown
  }
  browserContainer: BrowserInfoContainer
  fieldName?: string
  isNew?: boolean
  livekitContainer: LivekitContainer
}

type FieldMaskStateType = {
  fieldNames: string[]
  fieldCorners: number[][]
  currentCorners: number[]
  currentFieldName: string
  // new
  changeCurnerPoints: Array<number>
  saveCurner: boolean
  uploadCurners: boolean
}

export default class FieldMaskPage extends React.Component<FieldMaskPageProps, FieldMaskStateType> {
  constructor(props: FieldMaskPageProps) {
    super(props)
    this.state = {
      fieldNames: ['default'],
      fieldCorners: [defaultCorners],
      currentFieldName: '',
      currentCorners: [],
      changeCurnerPoints: [],
      saveCurner: false,
      uploadCurners: false
    }
  }

  componentDidMount = () => {
    this.getCornerPoints()
  }

  getCornerPoints = async () => {
    try {
      const callAs = searchAndSortGetCallAs([
        Role.admin,
        contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
      const res = await getCameraCornerPointsAll(this.props.cameraId, callAs)
      if (res.status === 200) {
        const fieldNames: string[] = []
        const fieldCorners: number[][] = []
        res.data.forEach((dataset) => {
          if (!dataset.Corners.corners.length) {
            dataset.Corners.corners = JSON.parse(JSON.stringify(defaultCorners))
          }
          if (this.props.fieldName === dataset.fieldName) {
            fieldNames.push(dataset.fieldName)
            fieldCorners.push(dataset.Corners.corners)
          }
        })

        this.setState({
          fieldNames,
          fieldCorners,
          currentCorners: fieldCorners[0],
          currentFieldName: fieldNames[0]
        })
        return
      }
      console.warn('Get Cornerpoints for', this.props.cameraId, ' Failed with Status', res.status)
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        console.warn('Get Cornerpoints for', this.props.cameraId, ' Failed with Status', err.response?.status)
      } else {
        console.warn('An unknown error occurred:', err)
      }
    }
  }

  renderBottomBtns = () => {
    return (
      <StaigeButton
        label={this.props.isNew ? i18n.t('general.create') : i18n.t('general.save')}
        style='white'
        iconMargin='0 -4px 0 4px'
        disabled={
          !this.state.saveCurner ||
          this.state.uploadCurners ||
          this.state.currentFieldName === '' ||
          this.state.currentFieldName === undefined
        }
        onClick={() => this.uploadCornerPoints()}
      />
    )
  }

  setCurnerPoints = (curnerPoints: Array<number>) => {
    this.setState({
      changeCurnerPoints: curnerPoints,
      saveCurner: true
    })
  }

  uploadCornerPoints = async () => {
    this.setState({ uploadCurners: true })
    let currentCorners = this.state.changeCurnerPoints
    currentCorners = currentCorners.map((x) => Math.round(x))
    const currentFieldName = this.state.currentFieldName
    let body = { Corners: { corners: currentCorners } }
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    const res = await getCameraCornerPointsFieldName(this.props.cameraId, currentFieldName, body, callAs)
    this.setState({ uploadCurners: false })
    if (res.status === 'Ok') {
      this.props.history.push(`/cameramonitor/${this.props.cameraId}`)
      return
    }
    console.error('Could not save Fieldmask:', res)
    alert(i18.t('matchfieldCalibration.saveError'))
  }

  render() {
    return (
      <PageWrapper
        headerTitle={`${i18n.t('fieldMask.headerTitleLoad')} ${this.props.camera?.hardwarePlatform} – ${
          this.props.camera?.RowKey
        }`}
        linkTo={`/cameramonitor/${this.props.camera.RowKey}`}
        simpleTopbar
        bottomBar={this.state.saveCurner ? this.renderBottomBtns : undefined}
      >
        <div>
          <div className='page-fieldMask'>
            <div className='fieldMask-container'>
              <div className='container-content'>
                <div className='content-header'>
                  <div className='header-title'>
                    {this.props.isNew
                      ? i18n.t('fieldMask.headerTitleNew')
                      : i18n.t('fieldMask.headerTitleEdit')}
                  </div>
                </div>
                <div className='content-bottom'>
                  <div className='bottom-input'>
                    <mobiscroll.Input
                      type={'text'}
                      value={this.state.currentFieldName}
                      disabled={!this.props.isNew}
                      onChange={(v) =>
                        this.setState({
                          currentFieldName: String(v.target.value).trim()
                        })
                      }
                      placeholder='Hauptfeld - 11er-Fußball...'
                      valid={true}
                    >
                      {i18n.t('fieldMask.inputFieldMaskTitle')}
                    </mobiscroll.Input>
                  </div>
                  <div className='bottom-fieldmaskContainer'>
                    <div className='fieldmaskContainer-header'>
                      <div className='header-title'>{i18n.t('fieldMask.fieldmaskContainerTitle')}</div>
                    </div>
                    <div className='fieldmaskContainer-content'>
                      <div className='content-interactiv'>
                        <div className='interactiv-field'>
                          <img src={field} />
                        </div>
                        <div className='interactiv-text'>{i18n.t('fieldMask.interactiveText')}</div>
                      </div>
                      <div className='content-prev'>
                        <PanoramaPlayer
                          cameraId={this.props.cameraId}
                          currentCorners={this.state.currentCorners}
                          setCurnerPoints={this.setCurnerPoints}
                          livekitContainer={this.props.livekitContainer}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}
