import { Container } from 'unstated-typescript'
import { getWebRTCConnection } from '../api/backstaige_v2'
import {
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomEvent,
  Track
} from 'livekit-client'

export type LivekitState = {
  connected: boolean
  livekit?: { url: string; token: string }
  element?: HTMLMediaElement
}

export class LivekitContainer extends Container<LivekitState> {
  constructor() {
    super()
    this.state = {
      connected: false,
      livekit: undefined,
      element: undefined
    }
  }

  livekitConnectionStart = async (cameraId: string) => {
    const res = await getWebRTCConnection(cameraId)
    this.livekitConnectToRoom(res.serverUrl, res.userToken)
  }

  livekitConnectToRoom = async (url: string, token: string) => {
    const handleTrackSubscribed = (
      track: RemoteTrack,
      publication: RemoteTrackPublication,
      participant: RemoteParticipant
    ) => {
      if (track.kind === Track.Kind.Video) {
        const element = track.attach()
        element.id = 'cameraCam'
        this.setState({
          element: element,
          connected: true,
          livekit: { url: url, token: token }
        })
      }
    }

    const handleTrackUnsubscribed = (
      track: RemoteTrack,
      publication: RemoteTrackPublication,
      participant: RemoteParticipant
    ) => {
      track.detach()
      this.setState({
        connected: false
      })
    }

    const handleDisconnect = () => {
      this.setState({
        connected: false
      })
    }

    if (url && token) {
      try {
        const room = new Room({})
        room.prepareConnection(url, token)
        room
          .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
          .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
          .on(RoomEvent.Disconnected, handleDisconnect)

        await room.connect(url, token)
      } catch (error) {
        console.error('Failed to connect to LiveKit', error)
      }
    }
  }
}

const webRTCContainer = new LivekitContainer()
export default webRTCContainer
