import React, { useEffect } from 'react'
import AiswSpinner from '../../pages/helper/aiswSpinner/aiswSpinner'

import { GridPlayerSetup } from '@soccerwatch/common'
import { LivekitContainer } from '../../services/livekitContainer'

import './player.scss'

type PanoramaPlayerProps = {
  cameraId: string
  currentCorners: number[]
  setCurnerPoints: (curnerPoints: Array<number>) => void
  livekitContainer: LivekitContainer
}

const defaultCorners = [1713, 1274, 7, 1289, 3823, 9, 2127, 2539]

export const PanoramaPlayer = (props: PanoramaPlayerProps) => {
  const panoPlayer = React.createRef<any>()
  const [connected, setConnected] = React.useState<boolean>(false)

  useEffect(() => {
    props.livekitContainer.livekitConnectionStart(props.cameraId)
  }, [])

  useEffect(() => {
    const { connected, element } = props.livekitContainer.state
    if (connected && element) {
      createPlayer(element)
    }

    if (!connected) {
      if (panoPlayer.current) {
        while (panoPlayer.current.firstChild) {
          panoPlayer.current.removeChild(panoPlayer.current.firstChild)
        }
      }
    }
  }, [props.livekitContainer.state.connected])

  const createPlayer = (element: HTMLMediaElement) => {
    if (panoPlayer.current) {
      const playerElement = document.createElement('staige-panorama-player') as any
      let copyCurrentCorners = []
      if (props.currentCorners === undefined || props.currentCorners.length === 0) {
        copyCurrentCorners = [...defaultCorners]
      } else {
        copyCurrentCorners = [...props.currentCorners]
      }

      const video = element as HTMLVideoElement | null

      if (video) {
        let trackOptions: GridPlayerSetup = {
          fieldSizeMeter: [60, 40],
          enableFieldMaskEdit: true,

          cameraId: props.cameraId,
          allowVr: false,
          useSource: 'player',
          playerSource: {
            video: video,
            cornerData: copyCurrentCorners
          }
        }

        playerElement.options = trackOptions
        panoPlayer.current?.append(playerElement as unknown as Node)

        playerElement.addEventListener('onSetCornerPoints', async (event: any) => {
          if (String(event.detail) !== String(props.currentCorners)) {
            props.setCurnerPoints(event.detail)
          }
        })

        setConnected(true)
      }
    }
  }

  return (
    <div className='comp-panoPlayer'>
      {!connected && (
        <div className='player-loading'>
          <AiswSpinner />
          <div className='loading-text'>Connection</div>
        </div>
      )}

      <div style={!connected ? { display: 'none' } : {}}>
        <div style={{ height: '100%' }} ref={panoPlayer} />
      </div>
    </div>
  )
}
